import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import { deleteQuestion, getAllQuestion } from "../../services/quiz-api";
import { useNavigate, useParams } from "react-router-dom";
import { findLevel, levels } from "../../utils/levels";
import { findType } from "../../utils/type";
import { useQuiz } from "providers/QuizProvider";
import { languages } from "utils/constants";

const pageSize = 10;
const checkContentType = async url => {
    try {
        const response = await fetch(url);
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.startsWith("image/")) {
            return "image";
        } else if (contentType && contentType.startsWith("video/")) {
            return "video";
        } else {
            return "unknown";
        }
    } catch (error) {
        console.error("Error checking content type:", error);
        return "error";
    }
};
const MyComponent = ({ url }) => {
    const [contentType, setContentType] = useState(null);

    useEffect(() => {
        const fetchContentType = async () => {
            if (url) {
                const type = await checkContentType(url);
                setContentType(type);
            }
        };

        fetchContentType();
    }, [url]);

    const renderContent = () => {
        switch (contentType) {
            case "image":
                return (
                    <>
                        <img src={url} style={{ maxWidth: "50px" }} alt="" />
                        <br />
                        Image
                    </>
                );
            case "video":
                // return <video controls width="50" src={url} />;
                return (
                    <>
                        <img src="../../../image/video.jpg" style={{ maxWidth: "50px" }} alt="" />
                        <br />
                        Video
                    </>
                );
            case "unknown":
                return <p>Unknown content type</p>;
            case "error":
                return <p>Error checking content type</p>;
            default:
                return null;
        }
    };

    return <div>{renderContent()}</div>;
};

const Question = () => {
    const navigate = useNavigate();
    const { quizId } = useParams();
    const { selectedLanguageId, updateSelctedLanguageId, selectedLevelId, updateSelctedLevelId } =
        useQuiz();

    const [question, setQuestion] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    const [filteredItems, setFilteredItems] = useState([]);
    const [currentItems, setCurrentItems] = useState([]);

    function deleteQuestionById(id) {
        deleteQuestion(id)
            .then(res => {
                setQuestion(question.filter(q => q.id !== id));
            })
            .catch(err => {
                console.log(err);
            });
    }

    function paginate(pageNumber) {
        localStorage.setItem(quizId, pageNumber);
        setCurrentPage(pageNumber);
    }

    useEffect(() => {
        if (quizId) {
            getAllQuestion(quizId, selectedLanguageId)
                .then(res => {
                    setQuestion(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [quizId, selectedLanguageId]);

    useEffect(() => {
        setFilteredItems(
            question
                .filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
                .filter(item => (selectedLevelId ? item.levelId === selectedLevelId : true))
        );
    }, [question, searchTerm, selectedLevelId]);

    useEffect(() => {
        const indexOfLastItem = currentPage * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;

        setCurrentItems(filteredItems.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, filteredItems]);

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Questions {`${filteredItems.length}/${question.length}`}</h2>
                    <div className="ms-auto me-2">
                        <select
                            id="language"
                            className="form-control"
                            value={selectedLevelId}
                            onChange={e => updateSelctedLevelId(Number.parseInt(e.target.value))}
                        >
                            <option value={0}>All levels</option>
                            {levels.map(level => (
                                <option key={level.id} value={level.id}>
                                    {level.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="me-2">
                        <select
                            id="language"
                            className="form-control"
                            value={selectedLanguageId}
                            onChange={e => updateSelctedLanguageId(e.target.value)}
                        >
                            <option value={-1}>All languages</option>
                            {languages.map(language => (
                                <option key={language.id} value={language.id}>
                                    {language.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => navigate(`/quiz/question/${quizId}/add`)}
                    >
                        Add Question
                    </button>
                </div>
                <input
                    type="text"
                    className="form-control mb-3 searchbox"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Type</th>
                            <th scope="col">Title</th>
                            <th scope="col">Level</th>
                            <th scope="col">Type</th>
                            <th scope="col">Option 1</th>
                            <th scope="col">Option 2</th>
                            <th scope="col">Option 3</th>
                            <th scope="col">Option 4</th>
                            <th scope="col">Correct Answer</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.length > 0 ? (
                            currentItems.map((list, index) => (
                                <tr key={list.uid}>
                                    <th scope="row">{(currentPage - 1) * pageSize + index + 1}</th>
                                    <th class="content-image-video">
                                        <MyComponent key={index} url={list?.image} />
                                    </th>
                                    <td className="td-style">{list.title}</td>
                                    <td>{findLevel(list.levelId)}</td>
                                    <td>{findType(list.type)}</td>
                                    <td>{list.option_1}</td>
                                    <td>{list.option_2}</td>
                                    <td>{list.option_3}</td>
                                    <td>{list.option_4}</td>
                                    <td>{list.correctAnswer}</td>
                                    <td className="custom-question">
                                        <button
                                            style={{ marginRight: "5px" }}
                                            className="btn btn-primary"
                                            onClick={() =>
                                                navigate(`/quiz/question/${quizId}/edit/${list.id}`)
                                            }
                                        >
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => deleteQuestionById(list.id)}
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr style={{ textAlign: "center" }}>
                                <td colSpan={11}>No records found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <nav>
                    <ul className="pagination mt-3">
                        {Array.from({ length: Math.ceil(filteredItems.length / pageSize) }).map(
                            (_, index) => (
                                <li
                                    key={index + 1}
                                    className={`page-item ${
                                        currentPage === index + 1 ? "active" : ""
                                    }`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => paginate(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                </li>
                            )
                        )}
                    </ul>
                </nav>
            </Layout>
        </>
    );
};

export default Question;
