import api from "./interceptor";

export const getAllQuiz = async langId => {
    try {
        const response = await api.get(`quiz?lang_id=${langId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getOneQuiz = async quizId => {
    try {
        const response = await api.get(`quiz/get/one/${quizId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getQuizMl = async (quizId, languageId) => {
    try {
        const response = await api.get(`quiz/get/quizml/${quizId}/${languageId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const addQuiz = async formData => {
    try {
        const response = await api.post(`quiz`, formData);

        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const editQuiz = async (quizId, formData) => {
    try {
        const response = await api.patch(`quiz/${quizId}`, formData);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getAllQuestion = async (quizId, langId) => {
    try {
        const response = await api.get(`quiz/question/all/${quizId}?lang_id=${langId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getOneQuestion = async questionId => {
    try {
        const response = await api.get(`quiz/question/q/${questionId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getQuestionMl = async (questionId, languageId) => {
    try {
        const response = await api.get(`quiz/questionml/q/${questionId}/${languageId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const addQuestion = async formData => {
    try {
        const response = await api.post(`quiz/question`, formData);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const editQuestion = async (questionId, formData) => {
    console.log("API....", questionId);
    try {
        const response = await api.patch(`quiz/question/q/${questionId}`, formData);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const deleteQuestion = async questionId => {
    try {
        const response = await api.delete(`quiz/question/q/${questionId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getAllLevel = async () => {
    try {
        const response = await api.get(`level`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getAllCompetition = async () => {
    try {
        const response = await api.get(`competition/view/all`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};
export const addCompetition = async formData => {
    try {
        const response = await api.post(`competition/add`, formData);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};
export const getOneCompetition = async competitionId => {
    try {
        const response = await api.get(`competition/${competitionId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const editCompetition = async (competitionId, formData) => {
    try {
        const response = await api.patch(`competition/update/${competitionId}`, formData);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const deleteCompetition = async competitionId => {
    try {
        const response = await api.delete(`competition/delete/${competitionId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};
export const getAllParticipants = async competitionId => {
    try {
        const response = await api.get(`competition/participants/${competitionId}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};
