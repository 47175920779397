import React, { useContext, useState } from "react";

const QuizContext = React.createContext({
    selectedLanguageId: -1,
    updateSelctedLanguageId: languageId => {},
    selectedLevelId: 0,
    updateSelctedLevelId: levelId => {}
});

export const QuizProvider = ({ children }) => {
    const [selectedLanguageId, setSelectedLanguageId] = useState(-1);
    const [selectedLevelId, setSelectedLevelId] = useState(0);

    function updateSelctedLanguageId(languageId) {
        setSelectedLanguageId(languageId);
    }

    function updateSelctedLevelId(levelId) {
        setSelectedLevelId(levelId);
    }

    return (
        <QuizContext.Provider
            value={{
                selectedLanguageId,
                updateSelctedLanguageId,
                selectedLevelId,
                updateSelctedLevelId
            }}
        >
            {children}
        </QuizContext.Provider>
    );
};

export function useQuiz() {
    const quizContext = useContext(QuizContext);

    return quizContext;
}
